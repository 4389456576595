<template>
  <div class="content">
    <div
      class="content-row"
      v-for="(step, stepIndex) in getEnableStep()"
      :key="stepIndex"
    >
      <div class="content-info">
        <span class="step-number">{{ step.index }}</span>
        <div class="step">
          <p class="step-name">{{ step.description }}</p>
          <div v-if="step.subdescriptionType === 'tags'" class="tags">
            <div
              v-for="(item, itemIndex) of step.subdescription"
              :key="itemIndex"
              class="tag"
            >
              {{ item }}
            </div>
          </div>
          <div class="d-flex align-center" v-else>
            <p v-if="step.subdescriptionType == 'text'">
              {{ step.subdescription }}
            </p>
          </div>
        </div>
      </div>

      <div class="content-action">
        <base-button
          design="three mini icon"
          :text="buttonText"
          @click="edit(step.index)"
        >
          <i class="fal fa-pen"></i>
        </base-button>
      </div>
    </div>

    <div class="d-flex mt_30 justify-end relative">
      <base-button class="big" :text="buttonText" @click="finish()" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/Button';
import { EnvironmentAPI } from '@/api/Environment';
import buildTagsHelper from '@/mixins/buildTagsHelper.js';

const components = {
  BaseButton,
};

const FIRING_TYPE = {
  CR: 'Conversões recorrentes',
  CU: 'Conversão única',
  RM: 'Recorrente no mês',
  RS: 'Recorrente na semana',
  PN: 'Pontual',
};

const STEPS = {
  NAME_STEP: 1,
  FIRING_STEP: 2,
  DELIVERY_STEP: 3,
  SEGMENTATION_STEP: 4,
  ANALYSIS_STEP: 5,
};

const SEGMENT_NAME = {
  behavioralDaysOff: 'Dias sem pedir',
  behavioralBirthday: 'Aniversariante',
  clientAgeRange: 'Faixa etária',
  clientRegisterTimer: 'Tempo de cadastro',
  clientAverageTicket: 'Ticket médio',
  clientOrderQuantity: 'Quantidade de conversões',
  clientTotalSpent: 'Total gasto',
  geographicDistrict: 'Região',
  regionRange: 'Região',
  weatherPhotography: 'Fotografia climática',
};

export default {
  components,
  props: {
    campaign: { type: Object, default: () => ({}) },
    buttonText: { type: String },
    goToStep: { type: Function },
    activeTab: { type: Number, default: 5 },
  },
  mixins: [buildTagsHelper],
  data() {
    return {
      STEPS,
      tabs: {
        NAME_STEP: {
          alias: 'NAME_STEP',
          index: 1,
          description: 'Nome da campanha',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        TRIGGER_STEP: {
          alias: 'TRIGGER_STEP',
          index: 2,
          description: 'Gatilho',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        FIRING_STEP: {
          alias: 'FIRING_STEP',
          index: 3,
          description: 'Disparo',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        DELIVERY_STEP: {
          alias: 'DELIVERY_STEP',
          index: 4,
          description: 'Método de envio',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        SEGMENTATION_STEP: {
          alias: 'SEGMENTATION_STEP',
          index: 5,
          description: 'Segmentação',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        ANALYSIS_STEP: {
          alias: 'ANALYSIS_STEP',
          index: 6,
          description: 'Análise',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
      },
    };
  },

  methods: {
    finish() {
      confirm(
        this.campaign.initial_flag > 1
          ? 'O calculo do resultado da análise poderá levar alguns minutos, nesse período você não poderá editar a campanha. Deseja iniciar?'
          : 'Confirma os dados e deseja salvar?',
        (res) => {
          if (res) {
            this.save();
            this.$emit('goToStep', 7);
          }
        },
        this.campaign.initial_flag > 1 ? 'Iniciar análise' : 'Salvar',
        'Cancelar'
      );
    },
    edit(tabIndex) {
      this.$emit('goToStep', tabIndex);
    },
    async save() {
      const { id } = this.$route.params;
      this.campaign.audience_visible = !!Object.keys(this.campaign).includes(
        'audience_name'
      );
      if (!this.campaign.audience_visible) {
        this.campaign.audience_name = '';
      }

      this.campaign.audience_rules = this.campaign.rules;
      Reflect.deleteProperty(this.campaign, 'rules');
      Reflect.deleteProperty(this.campaign, 'type_send');

      if (id) {
        delete this.campaign.status;
        return await EnvironmentAPI.updateCampaings(id, this.campaign);
      }
      return await EnvironmentAPI.postCampaigns(this.campaign);
    },

    getEnableStep() {
      const tabs = JSON.parse(JSON.stringify(this.tabs));

      return Object.values(tabs).filter((step) => step.enabled);
    },

    getDeliveryName(sendType) {
      switch (sendType) {
        case 'sms':
          return 'SMS';
        case 'whatsapp':
          return 'Whatsapp';
        case 'email':
          return 'E-mail';
        default:
          return '';
      }
    },
    buildSegmentationTags(segmentations = []) {
      const segments = [];
      segmentations.forEach((s) => {
        const { value, type: field } = s;

        if (value) {
          if (value.min || value.max) {
            segments.push(`${SEGMENT_NAME[field]} (${value.min}-${value.max})`);
          } else if (field === SEGMENT_NAME[field]) {
            segments.push(`${value.address}`);
          } else if (value.minimum) {
            segments.push(`${SEGMENT_NAME[field]} +(${value.minimum})`);
          } else if (value.exact) {
            segments.push(`${SEGMENT_NAME[field]} (${value.exact})`);
          } else if (field == 'regionRange') {
            const configs = value;
            segments.push(...configs.map((s) => {
                if(s.cities.length == 0) {
                    return s.state;
                } else if(s.cities.length == 1) {
                    const neighborhoods = s.cities[0].neighborhoods.length > 0 ? ` (${s.cities[0].neighborhoods.length})` : '';
                    return `${s.cities[0].city} - ${s.state}${neighborhoods}`;
                } else {
                    return `${s.state} (${s.cities.length})`;
                }
            }));            
          } else {
            segments.push(`${SEGMENT_NAME[field]}`);
          }

          if (value.isLast && value.field) {
            switch (value.field) {
              case '1':
                segments.push(`No(s) último(s) ${value.isLast} dia(s)`);
                break;
              case '2':
                segments.push(`No(s) último(s) ${value.isLast} mes(es)`);
                break;
              case '3':
                segments.push(`No(s) último(s) ${value.isLast} ano(s)`);
                break;
            }
          }
        } else {
          segments.push(`${SEGMENT_NAME[field]}`);
        }
      });
      return segments;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    $props: {
      immediate: true,
      deep: true,
      handler({ campaign }) {
        const { configs } = campaign;
        this.tabs.NAME_STEP.subdescription = campaign.name;
        this.tabs.DELIVERY_STEP.subdescription = this.getDeliveryName(
          campaign.type_send
        );
        this.tabs.SEGMENTATION_STEP.subdescription =
          campaign.rules && campaign.rules.length > 0
            ? this.buildSegmentationTags(campaign.rules)
            : [];

        this.tabs.TRIGGER_STEP.subdescription = this.buildTriggerTags(campaign);
        if (campaign.type !== 'PN') {
          this.tabs.FIRING_STEP.subdescription = this.buildFiringTags(campaign);
          this.tabs.FIRING_STEP.enabled = true;
        } else {
          this.tabs.FIRING_STEP.enabled = false;
        }

        if (campaign.initial_flag === 1) {
          this.tabs.ANALYSIS_STEP.subdescription = ['Sem análise'];
        } else if (campaign.initial_flag === 4) {
          this.tabs.ANALYSIS_STEP.subdescription = ['Toda a história'];
        } else {
          this.tabs.ANALYSIS_STEP.subdescription = ['Usuários Ativos'];
          if (configs.createdAtMonthsBefore) {
            this.tabs.ANALYSIS_STEP.subdescription.push(
              `${configs.createdAtMonthsBefore} ${
                configs.createdAtMonthsBefore > 1 ? 'meses' : 'mês'
              } atrás`
            );
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
}
.content-row {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 24px;
}
.content .content-row:not(:last-child) {
  border-bottom: 1px solid #6f767e26;
}

.content-row:first-child {
  padding-top: 0;
}
.content-info {
  display: flex;
}
.step-number {
  border-radius: 50%;
  border: 1px solid #6f767e59;
  font-weight: bold;
  color: #aaaaab;
  font-size: 12px;
  margin-right: 17px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #292e32;
}
.step-name {
  font-size: 14px;
  line-height: 17px;
  color: #999;
  margin-top: 7px;
}
.content-info p:not(.step-name, .tag-number) {
  color: rgba(190, 190, 191, 0.8);
  line-height: 15px;
  font-size: 12px;
  font-weight: 300;
  margin-top: 10px;
}
.action-button {
  padding: 15px 25px;
  width: fit-content;
  align-self: flex-end;
  margin-top: 25px;
}
.tags {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.tags .tag {
  color: rgba(170, 170, 171, 0.7);
  padding: 5px 15px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.2);
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

@media screen and (max-width: 991px) {
  .content {
    padding: 20px;
  }
}

.tag-number {
  color: rgba(170, 170, 171, 0.7);
  padding: 5px 15px;
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
  border-radius: 10px;
  font-weight: bold;
  border: 1px solid #ffffff17;
  margin-top: 10px;
}
</style>
